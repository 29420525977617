import { Box } from '@mui/material'
import Loading from 'components/Loading'
import { useAuth } from 'context/AuthContext'
import { useGlobals } from 'context/GlobalsContext'
import { type Assistant, assistantIdToSettings, getAssistantId } from 'features/assistants'
import { type Message, Role } from 'features/messages/types'
import { type Session } from 'features/sessions/types'
import { useRef } from 'react'
import { useIntl } from 'react-intl'
import { StyledStack, chatLayoutContainerSx } from './ChatLayout.styles'
import { MessageBox } from './MessageBox'

interface Props {
  session: Session
  messages: Message[]
  onFeedbackSubmitted: (idx: number, feedback: number) => void
  onCorrectionSubmitted: (idx: number, correction: string) => void
}

export const ChatLayout: React.FC<Props> = ({
  session, messages, onFeedbackSubmitted, onCorrectionSubmitted
}: Props) => {
  const intl = useIntl()
  const currentUser = useAuth()
  const windowHeight = useRef(window.innerHeight)
  const globals = useGlobals()
  if (globals === null || globals === undefined) {
    return <Loading />
  }

  const assistantId = getAssistantId(session, globals)
  const assistant = globals.assistantsById.get(assistantId)
  if (assistant === undefined) {
    throw new Error(`Assistant not found for id: ${assistantId}`)
  }

  const getGreetings = (assistant: Assistant, username: string): string => {
    if (assistant.id.startsWith('copilex')) {
      return intl.formatMessage({
        id: 'app.greetings.copilex',
        defaultMessage: (
          'Hi, {username}. How can I help? (Please note that I am still in Beta version, my answers ' +
          'can still be limited and/or incorrect. Use them carefully)'
        )
      }, { username })
    }
    return intl.formatMessage({
      id: 'app.greetings.default',
      defaultMessage: (
        'You are now interacting with {name}, a model developed by {author}. ' +
        'Answers are generated by {author}\'s model only. Gatewai has no control on them and is ' +
        'not responsible for their content. (Please note that this feature is provided for experimental purposes ' +
        'and may not be accessible at all time)'
      )
    }, { name: assistant.displayName, author: assistant.modelAuthor })
  }

  // Create greetings message to show at the top of the chat
  const settings = assistantIdToSettings(assistantId)
  const greetings = getGreetings(assistant, currentUser.userProfile.firstName)
  const greetingsMsg: Message = {
    role: Role.ASSISTANT,
    cleartextContent: greetings,
    anonContent: greetings,
    settings
  }

  return (
    <Box sx={chatLayoutContainerSx(windowHeight.current)}>
      <StyledStack spacing={1}>
        {/* Greetings (not an actual message) */}
        <MessageBox msg={greetingsMsg} disableButtons={true} />

        {/* Messages */}
        {messages.map((msg, idx) =>
          <MessageBox
            key={idx}
            msg={msg}
            onFeedbackSubmitted={(feedback) => { onFeedbackSubmitted(idx, feedback) }}
            onCorrectionSubmitted={(correction) => { onCorrectionSubmitted(idx, correction) }} />
        )}
      </StyledStack>
    </Box>
  )
}

export default ChatLayout
